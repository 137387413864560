<template>
    <b-container fluid>
        <div class="mb-2 row">
          <div class="col-12">
            <h4>{{ dealer.name}}</h4>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b-card no-body header-bg-variant="primary" header-border-variant="primary">
                    <b-tabs card>
                        <b-tab title="Allgemein" active>
                            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-md-6">

                                        <div class="form-group" v-if="dealer.thumbnail != ''">
                                            <div class="row align-items-center">
                                                <div class="col-md-5">
                                                    <label for="name" class="control-label">Name*</label>
                                                    <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                                    <has-error :form="form" field="name"></has-error>
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="number" class="control-label">Händlernummer*</label>
                                                    <input v-model="form.number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('number')}">
                                                    <has-error :form="form" field="number"></has-error>
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="discount" class="control-label">Rabatt(%)*</label>
                                                    <input v-model="form.discount" name="discount" id="discount" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('discount')}">
                                                    <has-error :form="form" field="discount"></has-error>
                                                </div>
                                                <div class="col-md-3">
                                                    <figure class="float-right mt-2" style="width: 70%; height: auto;">
                                                        <img v-bind:src="dealer.thumbnail" id="brandLogo" class="img-fluid" alt="img">
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group" v-if="dealer.thumbnail == ''">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label for="name" class="control-label">Name*</label>
                                                    <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                                    <has-error :form="form" field="name"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="number" class="control-label">Händlernummer*</label>
                                                    <input v-model="form.number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('number')}">
                                                    <has-error :form="form" field="number"></has-error>
                                                </div>

                                                <div class="col-md-4">
                                                    <label for="discount" class="control-label">Rabatt(%)*</label>
                                                    <input v-model="form.discount" name="discount" id="discount" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('discount')}">
                                                    <has-error :form="form" field="discount"></has-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="active" class="control-label">Online anzeigen?*</label>
                                            <select v-model="form.active" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('active')}">
                                                <option value="1">Ja</option>
                                                <option value="0">Nein</option>
                                            </select>
                                            <has-error :form="form" field="active"></has-error>
                                        </div>

                                        <div class="form-group">
                                            <label for="name" class="control-label">Beschreibung <span style="font-weight: 500">(in der Front sichtbar)</span></label>
                                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                            <has-error :form="form" field="description"></has-error>
                                        </div>

                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <div class="form-group">
                                                        <label for="street" class="control-label">Straße*</label>
                                                        <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}">
                                                        <has-error :form="form" field="street"></has-error>
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="house_number" class="control-label">Nr.*</label>
                                                        <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                                        <has-error :form="form" field="house_number"></has-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label for="zipcode" class="control-label">PLZ*</label>
                                                    <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                                    <has-error :form="form" field="zipcode"></has-error>
                                                </div>

                                                <div class="col-md-8">
                                                    <label for="city" class="control-label">Ort*</label>
                                                    <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}">
                                                    <has-error :form="form" field="city"></has-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="logo" class="control-label">Logo</label>
                                            <b-form-file id="logo" size="sm" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}"/>
                                            <has-error :form="form" field="logo"></has-error>
                                        </div>

                                        <div class="form-group">
                                            <label for="notice" class="control-label">Notizen <span style="font-weight: 500">(intern)</span></label>
                                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" :class="{'is-invalid': form.errors.has('notice')}"></ckeditor>
                                            <has-error :form="form" field="notice"></has-error>
                                        </div>

                                        <div class="form-group">
                                            <label for="sodataste" class="control-label">SodaTaste Händler?*</label>
                                            <select v-model="form.sodataste" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('sodataste')}">
                                                <option value="1">Ja</option>
                                                <option value="0">Nein</option>
                                            </select>
                                            <has-error :form="form" field="sodataste"></has-error>
                                        </div>

                                        <div class="form-group">
                                            <label for="invoicing" class="control-label">Händlerabrechnung?*</label>
                                            <select v-model="form.invoicing" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('invoicing')}">
                                                <option value="1">Ja</option>
                                                <option value="0">Nein</option>
                                            </select>
                                            <has-error :form="form" field="invoicing"></has-error>
                                        </div>

                                        <div class="row" v-if="notEmptyObject(dealer) && form.invoicing == 1">
                                            <div class="col-md-4">
                                                <label for="customer_id" class="control-label">Kundenkonto*</label><br>
                                                <button @click="selectCustomer" type="button" v-if="dealer.customer != null" class="btn btn-primary btn-sm form-control form-control-sm">Kunde ({{ dealer.customer.customernumber }}) ändern</button>
                                                <button @click="selectCustomer" type="button" v-else class="btn btn-primary btn-sm form-control form-control-sm">Kundekonto zuweisen</button>
                                            </div>

                                            <div class="col-md-4">
                                                <label for="billing_address_id" class="control-label">Rechnungsanschrift*</label>
                                                <select v-model="form.billing_address_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('billing_address_id')}">
                                                    <option value="choose">-- Bitte wählen --</option>
                                                    <option v-for="address in addresses" :key="address.id" :value="address.id">
                                                        {{ address.first_name }} {{ address.last_name }} /
                                                        <template v-if="address.type == 'address'">
                                                        {{ address.street }} {{ address.house_number }} /
                                                        </template>
                                                        <template v-if="address.type == 'packstation'">
                                                        {{ address.post_customer_number }} / Packstation {{ address.post_number }} /
                                                        </template>
                                                        <template v-if="address.type == 'postfiliale'">
                                                        {{ address.post_customer_number }} / Postfiliale {{ address.post_number }} /
                                                        </template>
                                                        {{ address.zipcode }} {{ address.city }}
                                                    </option>
                                                </select>
                                                <has-error :form="form" field="billing_address_id"></has-error>
                                            </div>

                                            <div class="col-md-4">
                                                <label for="shipping" class="control-label">Lieferanschrift*</label>
                                                <select v-model="form.shipping_address_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('shipping_address_id')}">
                                                    <option value="choose">-- Bitte wählen --</option>
                                                    <option v-for="address in addresses" :key="address.id" :value="address.id">
                                                        {{ address.first_name }} {{ address.last_name }} /
                                                        <template v-if="address.type == 'address'">
                                                        {{ address.street }} {{ address.house_number }} /
                                                        </template>
                                                        <template v-if="address.type == 'packstation'">
                                                        {{ address.post_customer_number }} / Packstation {{ address.post_number }} /
                                                        </template>
                                                        <template v-if="address.type == 'postfiliale'">
                                                        {{ address.post_customer_number }} / Postfiliale {{ address.post_number }} /
                                                        </template>
                                                        {{ address.zipcode }} {{ address.city }}
                                                    </option>
                                                </select>
                                                <has-error :form="form" field="shipping_address_id"></has-error>
                                            </div>

                                            
                                        </div>

                                        



                                        <div class="form-group mt-4" v-if="$auth.check('dealers.edit')">
                                            <b-button size="sm" variant="success" @click="editDealer">Speichern</b-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <GmapMap
                                        :center="{lat:latitude, lng:longitude}"
                                        :zoom="15"
                                        map-type-id="terrain"
                                        style="width: 100%; height: 100%"
                                        >
                                            <GmapMarker
                                                :position="google && new google.maps.LatLng(dealer.longitude, dealer.latitude)"
                                            />
                                        </GmapMap>
                                    </div>
                                </div>
                            </form>
                        </b-tab>
                        <b-tab title="Artikel">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3>Zugewiesene Artikel</h3>
                                </div>
                                <div class="text-right col-md-6">
                                    <button class="mr-1 btn btn-secondary btn-sm" href="#" @click="showLabelModal" v-if="$auth.check('articles.print')" :disabled="selectedArticles.length <= 0"><i class="fas fa-print"></i> Preisschild drucken</button>
                                    <!-- <button v-if="selectedArticles.length > 0" class="mr-1 btn btn-sm btn-danger" @click="deleteArticles"><i class="fas fa-trash"></i> Markierte Artikel löschen</button> -->
                                    <button v-if="$auth.check('dealers.edit')" class="mr-1 btn btn-sm btn-dark" @click="openCopyModal"><i class="fas fa-copy"></i> Kopieren nach</button>
                                    <button v-if="$auth.check('dealers.export')" class="mr-1 btn btn-sm btn-warning" @click="generatePicklist"><i class="fas fa-file-export"></i> Lieferschein Export</button>
                                    <button v-if="$auth.check('dealers.export')" class="mr-1 btn btn-sm btn-success" @click="exportArticles"><i class="fas fa-file-export"></i> CSV Export</button>
                                    <button v-if="$auth.check('dealers.create')" class="btn btn-sm btn-primary" @click="showArticleModal"><i class="fas fa-plus"></i> Hinzufügen</button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="articles.length <= 0">Diesem Händler sind noch keine Artikel zugewiesen</span>
                                    <table v-else class="table table-striped">
                                        <thead>
                                                <th><input type="checkbox" v-model="selectAll" /></th>
                                                <th>Artikelnummer</th>
                                                <th>Artikelname</th>
                                                <th>Hersteller</th>
                                                <th>Preis</th>
                                                <th>Steuersatz</th>
                                                <th>Optionen</th>
                                                <th style="width:15%; text-align:center;">Aktionen</th>
                                        </thead>

                                        <tbody>
                                            <tr v-for="(article,index) in articles.data" :key="article.id">
                                                <td><input type="checkbox" v-model="selectedArticles" :value="article.article.id" number /></td>
                                                <td>{{ article.ordernumber }}</td>
                                                <td>{{ generateName(index) }}</td>
                                                <td>{{ article.article.producer.name }}</td>
                                                <td>{{ article.price | toCurrency}}</td>
                                                <td>{{ article.tax }}</td>
                                                <td>
                                                    <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                    <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                    <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                    <span class="ml-2 badge bg-warning" v-if="article.article.seasonal == 1">Saison </span>
                                                </td>
                                                <td style="text-align:center;">
                                                    <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                                                    <b-button size="sm" @click="deleteArticle(article.id)" variant="danger"><i class="fas fa-trash"></i></b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="clearfix align-middle card-footer">
                                <pagination v-if="notEmptyObject(articles)" class="float-left" :data="articles" @pagination-change-page="getArticles" :limit="3"></pagination>
                                
                                <select class="float-right form-control-sm" v-model="params.per_page">
                                    <option value="25">25 Artikel</option>
                                    <option value="50">50 Artikel</option>
                                    <option value="75">75 Artikel</option>
                                    <option value="100">100 Artikel</option>
                                    <option value="125">125 Artikel</option>
                                    <option value="150">150 Artikel</option>
                                </select>
                                <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                            </div>
                            <CopyToDealer :dealer="dealer"></CopyToDealer>
                        </b-tab>
                        <b-tab title="Überprüfungen">
                            <table class="table table-striped table-hover" v-if="checks.length > 0">
                                <thead>
                                    <tr>
                                        <th>Datum</th>
                                        <th>Fehlende Artikel</th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="check in checks" :key="check.id">
                                        <td>{{check.created_at | formatDateTime}}</td>
                                        <td>{{check.items.length}}</td>
                                        <td>
                                            <button class="btn btn-success btn-xs mr-1" v-b-popover.hover.top="'Ansehen'" @click="openCheckModal(check)">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                            <!-- <button class="btn btn-warning btn-xs mr-1" v-b-popover.hover.top="'Bearbeiten'">
                                                <i class="fas fa-edit"></i>
                                            </button> -->
                                            <button class="btn btn-danger btn-xs" v-b-popover.hover.top="'Löschen'" @click="deleteCheckModal(check)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 v-else>
                                Es sind bisher keine Artikelüberprüfungen durchgeführt worden
                            </h3>
                            <CheckModal :check="articleCheckModal"></CheckModal>
                        </b-tab>
                        <b-tab title="Kontakte">
                            <b-row class="mb-3" >
                                <b-col md="3">
                                    <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                                </b-col>
                                <b-col md="3" offset-md="6" class="text-right">
                                    <b-button size="sm" @click="createModal" class="mr-1" variant="primary"><i class="fas fa-plus-circle" v-if="$auth.check('dealers.create')"></i> Neuer Kontakt</b-button>
                                </b-col>
                            </b-row>
                            <b-row v-if="contacts.length >= 1">
                                <b-col>
                                    <b-table striped hover outlined :fields="contactFields" :items="contacts" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                        <template #cell(actions)="row">
                                            <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('dealers.edit')"><i class="fas fa-edit" ></i></b-button>
                                            <b-button size="sm" @click="deleteContact(row.item.id)" variant="danger" v-if="$auth.check('dealers.destroy')"><i class="fas fa-trash" ></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                                </b-col>
                            </b-row>
                        
                            <b-row v-if="contacts.length == 0">
                                <b-col>
                                    <h4>Dieser Händler hat keine Kontakte</h4>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab title="Dokumente" v-if="$auth.check('dealers.documents')">
                            <Documents :dealer="dealer" v-if="notEmptyObject('dealer')"/>
                        </b-tab>
                        <b-tab title="Statistik" :disabled="checkStats()" lazy ref="statsTab">
                            <Statistic :dealer="dealer" v-if="notEmptyObject('dealer')"/>
                        </b-tab>
                        <b-tab title="Aktivitäten" lazy>
                            <Timeline :dealer="dealer" v-if="notEmptyObject('dealer')"/>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>

        <b-modal id="contactModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, contactForm.id)">
                <form ref="contactForm" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="contactForm.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('name')}">
                                <has-error :form="contactForm" field="name"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="department" class="control-label">Abteilung*</label>
                                <input v-model="contactForm.department" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('department')}">
                                <has-error :form="contactForm" field="department"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail*</label>
                                <input v-model="contactForm.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('email')}">
                                <has-error :form="contactForm" field="email"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="phone" class="control-label">Telefon*</label>
                                <input v-model="contactForm.phone" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('phone')}">
                                <has-error :form="contactForm" field="phone"></has-error>
                            </div>

                            
                        </div>
                    </div>
                </form>
            </b-modal>

            <b-modal id="labelModal" title="Artikeletiketten drucken" ok-only ok-variant="danger" ok-title="Drucken" size="l" @ok="doLabels">

            <div class="form-group">
                <label>Modus</label>
                <select class="form-control form-control-sm" v-model="label.mode" :disabled="usePrintnode == 'false'">
                    <option value="print">Direkt drucken</option>
                    <option value="pdf">Download als PDF</option>
                </select>
            </div>

            <div class="form-group" v-if="label.mode == 'print'">
                <label>Filiale</label>
                <select class="form-control form-control-sm" v-model="label.printer">
                    <option v-for="printer in printers" :key="printer.id" :value="printer.id">{{ printer.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Template</label>
                <select class="form-control form-control-sm" v-model="label.template">
                    <option :value="null">Standard</option>
                    <option v-for="priceTemplate in priceLabels" :key="priceTemplate.id" :value="priceTemplate.id">{{ priceTemplate.title }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Anzahl pro Etikett</label>
                <input type="number" class="form-control form-control-sm" v-model="label.qty" />
            </div>

        </b-modal>

            <ArticleModal />

            <b-modal id="customerFullModal" title="Kunde wählen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Versanddaten</h3>
                <div class="card-tools">
                   
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getFullCustomers" v-model="search" />
                        <button type="submit" class="btn btn-default" @click.prevent="getFullCustomers">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                                    
                                
                </div>
              </div>
              <div class="card-body">
                <table class="table table-striped">
                                    <thead>
                                        <tr data-widget="expandable-table" aria-expanded="false">
                                            <th>
                                                Kundennummer
                                            </th>
                                            <th>
                                                E-Mail
                                            </th>
                                            <th>
                                                Firma
                                            </th>
                                            <th>
                                                Vorname
                                            </th>
                                            <th>
                                                Nachname
                                            </th>
                                            <th>
                                                PLZ
                                            </th>
                                            <th>
                                                Stadt
                                            </th>
                                            <th>Aktionen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="customer in customers.data" :key="customer.id">
                                            <td>{{ customer.customernumber}}</td>
                                            <td>{{ customer.email}}</td>
                                            <td>{{ customer.billing_address.company }}</td>
                                            <td>{{ customer.first_name }}</td>
                                            <td>{{ customer.last_name }}</td>
                                            <td>{{ customer.billing_address.zipcode }}</td>
                                            <td>{{ customer.billing_address.city }}</td>
                                            <td>
                                                <b-button size="xs" @click="setCustomer(customer)" variant="primary"><i class="fas fa-user"></i> Auswählen</b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
              </div>
              <div class="card-footer">
                        <pagination class="float-left" :data="customers" @pagination-change-page="getFullCustomers" :limit="3"></pagination>
                        <p v-if="notEmptyObject(customers)" class="float-right mr-2">Anzeige Eintrag {{ customers.meta.from }} - {{ customers.meta.to }} von {{ customers.meta.total }}</p>
                    </div>
            </div>
          </div>
        </div>

        
    </b-modal>
    </b-container>
</template>

<script>
import { gmapApi } from 'gmap-vue';
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';

import ArticleModal from './components/AddModal';
import Documents from './components/Documents.vue';
import Statistic from './components/Statistic.vue';
import Timeline from './components/Timeline.vue';
import CheckModal from './components/CheckModal.vue';
import CopyToDealer from './components/CopyToDealer.vue';

export default {
    name: "Dealer-Details",
    title: "Händlerdetails",

    components: {
        ArticleModal,
        Documents,
        CheckModal,
        CopyToDealer,
        Statistic,
        Timeline
    },

    data() {
        return {
            usePrintnode: process.env.VUE_APP_USE_PRINTNODE,
            search: '',
            form: new window.UploadForm({
                id: "",
                name: "",
                number: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                logo: [],
                notice: "",
                discount: "",
                active: "",
                invoicing: "0",
                billing_address_id: 'choose',
                shipping_address_id: 'choose',
                customer_id: '',
                sodataste: "0",
                _method: "PUT",
            }),
            contactForm: new window.Form({
                id: "",
                name: "",
                department: '',
                phone: '',
                email: '',
            }),
            editor: ClassicEditor,
            customers: {},
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            addresses: [],
            modalTitle: "Neuen Kontakt erstellen",
            contacts: [],
            dealer: [],
            editMode: false,
            editModeContact: false,
            latitude: 0,
            longitude: 0,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            printers: [],
            dealers: [],
            label:{
                mode: 'print',
                printer: '',
                qty: 1,
                template: null,
            },
            contactFields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "department", label: "Abteilung", sortable: true},
                {key: "email", label: "E-Mail", sortable: true },
                {key: "phone", label: "Telefon", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            selectedArticles: [],
            articles: [],
            checks: [],
            articleCheckModal: null,
            params: {
                per_page: 50
            },
            priceLabels: [],
            currentTab: 0,
        }
    }, 
    watch: {
        params: {
            handler() {
                this.getArticles();
            },
            deep: true
        }
    },
    methods:{
        checkStats(){
            if(Array.isArray(this.dealer) == true){
                return true;
            }

            if (typeof this.dealer === 'object' && !Array.isArray(this.dealer) && this.dealer !== null) {
                if(this.dealer.customer_id != null && this.dealer.billing_address_id != null && this.dealer.shipping_address_id != null){
                    return false;
                }
                return true;
            }
            return true;
        },

        setCustomer(customer){
            this.dealer.customer = customer;
            this.addresses = customer.addresses;
            this.form.customer_id = customer.id;
            this.form.billing_address_id = 'choose';
            this.form.shipping_address_id = 'choose';
            this.$bvModal.hide('customerFullModal');
        },

        selectCustomer()
        {
            this.getFullCustomers();
            this.$bvModal.show('customerFullModal');
        },

        generatePicklist(){
            this.axios
                .get("/dealers/" + this.$route.params.id + '/articles/export/picklist',{
                    responseType: 'blob',
                })
                .then((response) => {
                    this.$Progress.finish();
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'Lieferschein-'+ this.dealer.name +'.pdf');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        doLabels(bvModalEvt){
            bvModalEvt.preventDefault();

            if(this.label.mode == 'pdf')
            {
                //Die ganzen Etiketten bitte als PDF
                this.labelPdf();
            }

            if(this.label.mode == 'print')
            {
                this.labelPrint();
            }

        },

        labelPrint(){
            this.axios
            .post('/articles/label/print', {
                qty: this.label.qty,
                store_id: this.label.printer,
                articles: this.selectedArticles,
                price_label_id: this.label.template,
            })
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Druck erfolgreich!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.label.qty = 1;
                this.$bvModal.hide('labelModal');
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },


        labelPdf(){
            this.axios
            .post('/articles/label/download', {
                qty: this.label.qty,
                articles: this.selectedArticles,
                price_label_id: this.label.template,
            },
            {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                this.$swal({
                    icon: "success",
                    title: "Download erfolgreich!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.label.qty = 1;
                this.$bvModal.hide('labelModal');
                this.downloadLabel(response)
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        downloadLabel(response){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Etiketten.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)

        },


        showLabelModal(){
            if(this.usePrintnode == 'false')
            {
                this.label.mode = 'pdf';
            }
            this.$bvModal.show('labelModal');
        },

        exportArticles(){
            this.$Progress.start();
            this.axios
                .get("/dealers/" + this.$route.params.id + '/articles/export/csv',{
                    responseType: 'blob',
                })
                .then((response) => {
                    this.$Progress.finish();
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'Stammdaten.xlsx');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteArticle(article){
            this.$swal({
                title: "Möchtest du den Artikel wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    var articles = [article];
                    this.axios
                        .delete("/dealers/" + this.dealer.id + '/articles', {
                            data: {
                                'selectedArticles': articles
                            }
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.getArticles();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        deleteArticles(){
            this.$swal({
                title: "Möchtest du die selektierten Artikel wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/dealers/" + this.dealer.id + '/articles', {
                            data: {
                                'selectedArticles': this.selectedArticles
                            }
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.selectedArticles = [];
                            this.getArticles();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
        },

        showArticleModal(){
            this.$bvModal.show("articleModal");
        },
        

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editModeContact == true)
            {
                //Edit User
                this.editContact(id);
            }
            else
            {
                //Create User
                this.createContact();
            }
        },

        createModal() {
            this.contactForm.clear();
            this.contactForm.reset();
            this.editModeContact = false;
            this.$bvModal.show("contactModal");
        },

        editModal(Contact) {
            this.editModeContact = true;
            this.modalTitle = "Kontakt bearbeiten";
            this.contactForm.reset();
            this.contactForm.fill(Contact);
            this.$bvModal.show("contactModal");

        },
        openCheckModal(check) {
            this.articleCheckModal = check;
            this.$bvModal.show("checkArticleModal");
        },
        deleteCheckModal(check) {
            this.$swal({
                title: "Möchtest du das Fahrzeug wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                if(result.isConfirmed)
                {
                    this.axios
                        .delete('/dealers/' + this.$route.params.id + '/articles/check/' + check.id)
                        .then(() => {
                            this.getChecks();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Fehler beim löschen der Artikel Überprüfung'
                            })
                        })
                }
            });

        },
        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.dealer);
            this.form._method ="PUT";
            this.editMode = false;
        },

        openCopyModal() {
            this.$bvModal.show('copyModal');
        },

        createContact() {
            this.$Progress.start();
            this.contactForm
                .post("/dealers/" + this.dealer.id + '/contacts')
                .then(() => {
                    this.loadDealer();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Kontakt wurde hinzugefügt",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        editContact(id) {
            this.$Progress.start();
            this.contactForm
                .put("/dealers/" + this.dealer.id + '/contacts/' + id)
                .then(() => {
                    this.loadDealer();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Der Kontakt wurde editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        deleteContact(id){
            this.$swal({
                title: "Möchtest du den Kontakt wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/dealers/" + this.dealer.id + '/contacts/' + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kontakt wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadDealer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        editDealer() {
            this.$Progress.start();
            this.form
                .post("/dealers/"+this.dealer.id)
                .then(() => {
                    this.loadDealer();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Händler wurde editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        loadDealer() {
            this.axios
                .get("/dealers/" + this.$route.params.id)
                .then((response) => {
                    this.dealer = response.data.data;
                    this.contacts = this.dealer.contacts;
                    if(this.dealer.notice == null)
                    {
                        this.dealer.notice = "";
                    }
                    if(this.dealer.description == null)
                    {
                        this.dealer.description = "";
                    }
                    this.form.fill(this.dealer);
                    if(this.dealer.customer_id != null && this.dealer.billing_address_id != null && this.dealer.shipping_address_id != null){
                        this.form.invoicing = 1;
                        this.addresses = this.dealer.customer.addresses;
                    }
                    else{
                        this.form.invoicing = 0;
                        this.form.shipping_address_id = 'choose';
                        this.form.billing_address_id = 'choose';
                    }
                    this.form._method = "PUT";
                    this.latitude = parseFloat(this.dealer.longitude);
                    this.longitude = parseFloat(this.dealer.latitude);
                    // if(this.$route.params.stats != undefined)
                    // {
                    //     if(this.$route.params.stats == true){
                    //         //Redirect to Stats Tab
                    //         console.log('Tab match')
                    //         console.log(this.$refs.statsTab.activate());
                    //     }
                    // }
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getPrinters() {
            this.$Progress.start();
            this.axios
                .get("/articles/label/printers")
                .then((response) => {
                    this.printers = response.data;
                    
                    if(Object.keys(response.data).length > 0)
                    {
                        this.label.printer = response.data[0].id;
                    }
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getPriceLabels() {
            this.axios
                .get("/price-labels")
                .then((response) => {
                    this.priceLabels = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getFullCustomers(page = 1){
            this.axios
                .get("/customers?search=" + this.search + '&page=' + page)
                .then((response) => {
                    this.customers = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        getArticles(page) {
            if(page == undefined)
            {
                page = this.page 
            }

            this.axios
                .get("/details", {
                    params: {
                        dealer: this.$route.params.id,
                        page,
                        ...this.params
                    }

                })
                .then((response) => {
                    this.articles = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Artikel ist etwas schief gelaufen'
                    });
                });
        },
        getChecks()
        {
            this.axios
                .get('/dealers/' + this.$route.params.id + '/articles/check')
                .then((response) => {
                    this.checks = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Artikel Überprüfungen ist etwas schief gelaufen'
                    })
                })
        },
    },

    created() {
        this.loadDealer();
        this.getPrinters();
        this.getArticles();
        this.getPriceLabels();
        this.getChecks();
        // this.getCustomers();
    },

    computed: {

        google: gmapApi,

        rows() {
            return this.contacts.length;
        },

        selectAll: {
            get: function () {
                return this.articles.data ? this.selectedArticles.length == this.articles.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.articles.data.forEach(function (article) {
                        selected.push(article.article.id);
                    });
                }
                this.selectedArticles = selected;
            }
        }

    },
}
</script>

<style>

</style>