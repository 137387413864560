<template>
    <b-modal id="checkArticleModal" title="Artikel Überprüfung" ok-only ok-variant="secondary" ok-title="Schließen" size="xl">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Artikelnummer</th>
                    <th>Bezeichnung</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items.data" :key="item.id">
                    <td>{{item.detail.ordernumber}}</td>
                    <td>{{generateName(item.detail)}}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <div class="clearfix align-middle">
            <pagination v-if="notEmptyObject(items)" class="float-left" :data="items" @pagination-change-page="getArticles" :limit="3"></pagination>
            
            <select class="float-right form-control-sm" v-model="params.per_page">
                <option value="25">25 Artikel</option>
                <option value="50">50 Artikel</option>
                <option value="75">75 Artikel</option>
                <option value="100">100 Artikel</option>
                <option value="125">125 Artikel</option>
                <option value="150">150 Artikel</option>
            </select>
            <p v-if="notEmptyObject(items)" class="float-right mr-2">Anzeige Eintrag {{ items.meta.from }} - {{ items.meta.to }} von {{ items.meta.total }}</p>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: ['check'],
    data(){
        return {
            items: [],
            params: {
                per_page: 75
            },
            page: 1,
        }
    },
    watch: {
        check: {
            handler() {
                this.getArticles();
            },
            deep: true,
        },
        params: {
            handler() {
                this.getArticles();
            },
            deep: true,
        }
    },

    methods: {
        getArticles(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get('/dealers/' + this.check.dealer_id + '/articles/check/' + this.check.id, {
                    params: {
                        page,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.items = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Artikel ist etwas schief gelaufen'
                    })
                })
        },
        generateName(detail)
        {
            if(detail.article != undefined)
            {
                var name = detail.article.name;
    
                detail.values.forEach(function (value) {
                    name = name + ' - ' + value.name;
                });
    
                return name;
            }
        },
    },

    mounted() {

    }

}
</script>

<style>

</style>