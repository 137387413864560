<template>
    <b-modal id="copyModal" title="Artikel kopieren" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
        <div class="input-group input-group-sm">
            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadDealers" v-model="search" />
            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadDealers">
                <i class="fas fa-fw fa-search"></i>
            </button>
            <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                <i class="fas fa-fw fa-times"></i>
            </button>
        </div>
        <table class="table table-hover table-striped" v-if="dealers">
            <thead>
                <tr>
                    <th>Nr.</th>
                    <th>Händler</th>
                    <th>Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="dealer in dealers.data" :key="dealer.id">
                    <td>{{ dealer.number }}</td>
                    <td>{{ dealer.name }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm" @click="submitCopyDealer(dealer)">
                            <i class="fas fa-plus"></i> Auswählen
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="dealers">
            <template v-if="dealers.data.length > 0">
                <div class="row align-items-center">
                    <div class="col-md-9">
                        <pagination class="float-left" :data="dealers" @pagination-change-page="loadDealers" :limit="3"></pagination>
                    </div>
                    <div class="col-md-2">
                        <span class="float-right">Anzeige Eintrag {{ dealers.meta.from }} - {{ dealers.meta.to }} von {{ dealers.meta.total }}</span>
                    </div>
                    <div class="col-md-1">
                        <select class="float-right form-control form-control-sm" v-model="params.per_page">
                            <option value="25">25 Händler</option>
                            <option value="50">50 Händler</option>
                            <option value="75">75 Händler</option>
                            <option value="100">100 Händler</option>
                            <option value="125">125 Händler</option>
                            <option value="150">150 Händler</option>
                        </select>
                    </div>
                </div>                        
            </template>
        </div>
    </b-modal>
</template>

<script>
export default {
    data(){
        return {
            dealers: null,
            params: {
                    sort_field: 'number',
                    sort_direction: 'asc',
                    per_page: 50,
                },
            search: '',
            page: 1,
        }
    },

    props: ['dealer'],

    computed: {
    },

    watch: {
        params: {
            handler() {
                this.loadDealers();
            },
            deep: true
        }
    },

    methods: {
        loadDealers(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get('/dealers', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.dealers = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Händler ist etwas schief gelaufen'
                    })
                })
        },
        submitCopyDealer(dealer) {
            this.$swal({
                    title: "Möchtest du wirklich alle Artikel nach " + dealer.name + " kopieren?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, kopieren`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        // console.log(this.$parent.form);
                        this.axios
                            .post("dealers/" + this.dealer.id + "/copy", {
                                targetDealer: dealer.id
                            })
                            .then(() => {
                                this.$bvModal.hide("copyModal");
                            })
                            .catch(() => {
                                this.$swal({
                                    'icon': 'error',
                                    'title': 'Oops...',
                                    'text': 'Beim Kopieren ist etwas schief gelaufen'
                                })
                            })
                    }
                });
        },
        resetSearch(){
            this.search = '';
            this.loadDealers();
        },
    },

    mounted() {
        this.loadDealers();
    }

}
</script>

<style>

</style>