<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                <h5><i class="fa fa-briefcase mr-1"></i>Artikelumsätze</h5>
                <h6 class="text-primary">Gewählter Zeitraum: {{ articles.start_date | formatDate }} - {{ articles.stop_date | formatDate }}</h6>
                <h6 class="text-danger" v-if="articles.start_compare_date != '' && articles.stop_compare_date != ''">Gewählter Vergleichs-Zeitraum: {{ articles.start_compare_date | formatDate }} - {{ articles.stop_compare_date | formatDate }}</h6>
            </div>
            <div class="card-tools">
                <button type="button" class="btn btn-warning" @click="exportStats()"><i class="fas fa-file-export mr-1"></i>Export</button>
            </div>
        </div>
        <div class="card-body">
            <div id="accordion">
                <div class="card ">
                    <div class="card-header collapsed card-link" 
                        data-toggle="collapse" 
                        data-target="#collapseOne">
                        <h5 class="card-title"><strong>Filtern</strong></h5>
                        <button class="btn btn-primary btn-sm float-right"><i class="fas fa-chevron-down"></i></button>
                    </div>
                    <div id="collapseOne" 
                        class="collapse" 
                        data-parent="#accordion">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="text-danger"><strong>Allgemein</strong></h6>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Hersteller:</label>
                                        <div class="col-md-9">
                                        <select class="form-control form-control-sm" v-model="params.producer_id">
                                            <option :value="null">-- Filter Hersteller --</option>
                                            <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{producer.number}} - {{ producer.name }}</option>
                                        </select>
                                        </div>   
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Suche:</label>
                                        <div class="col-md-9">

                                        <div class="input-group input-group-sm" style="margin-top: 0;">
                                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="reload" v-model="params.search" />
                                            <button v-if="this.params.search != ''" type="button" class="btn btn-danger btn-sm" @click="resetSearch">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <h6 class="text-danger"><strong>Zeitraum</strong></h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Von:</label>
                                        <div class="col-md-9">
                                            <input type="date" class="form-control form-control-sm" v-model="params.start_date" />
                                        </div>   
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Bis:</label>
                                        <div class="col-md-9">
                                            <input type="date" class="form-control form-control-sm" v-model="params.stop_date" />
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchCompare" v-model="showCompare">
                                        <label class="custom-control-label" for="customSwitchCompare">Vergleichs-Zeitraum</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" v-if="showCompare">
                                <div class="col-md-12">
                                    <h6 class="text-danger"><strong>Vergleichs-Zeitraum</strong></h6>
                                </div>
                            </div>

                            <div class="row" v-if="showCompare">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Von:</label>
                                        <div class="col-md-9">
                                            <input type="date" class="form-control form-control-sm" v-model="params.start_compare_date" />
                                        </div>   
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Bis:</label>
                                        <div class="col-md-9">
                                            <input type="date" class="form-control form-control-sm" v-model="params.stop_compare_date" />
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="row">    
                                <div class="col-md-12">
                                    <div class="form-group row float-right">
                                        <div class="col-md-9">
                                        <button class="btn btn-primary" @click="reload">
                                            <i class="fas fa-sync"></i>
                                        </button>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive" v-if="articles.articles.data.length > 0">
                <table class="table table-hover table-sm">
                    <thead>
                        <th>
                            <a href="#" @click.prevent="changeSort('articleordernumber')">Artikelnummer</a>
                            <span v-if="this.params.sort_field == 'articleordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'articleordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <th>
                            <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                            <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <th><a href="#" @click.prevent="changeSort('sales')">Umsatz</a>
                            <span v-if="this.params.sort_field == 'sales' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'sales' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <th><a href="#" @click.prevent="changeSort('qty')">Stückzahl</a>
                            <span v-if="this.params.sort_field == 'qty' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'qty' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <template  v-if="articles.start_compare_date != '' && articles.stop_compare_date != ''">
                        <th>
                            Umsatz (VGZ)
                            <!-- <a href="#" @click.prevent="changeSort('sales_compare')">Umsatz (Vergleichszeitraum)</a>
                            <span v-if="this.params.sort_field == 'sales_compare' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'sales_compare' && this.params.sort_direction == 'desc'" >&darr;</span> -->
                        </th>
                        <th>
                            Stückzahl (VGZ)
                            <!-- <a href="#" @click.prevent="changeSort('sales_amount_compare')">Stückzahl (Vergleichszeitraum)</a>
                            <span v-if="this.params.sort_field == 'sales_amount_compare' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'sales_amount_compare' && this.params.sort_direction == 'desc'" >&darr;</span> -->
                        </th>
                        </template>
                    </thead>
                    <tbody>
                        <tr v-for="(article) in articles.articles.data" :key="article.id">
                        <td>{{article.articleordernumber}}</td>
                        <td>{{article.article_name}}</td>
                        <td>{{article.producer_name}}</td>
                        <td>{{ Number(article.total_umsatz) | toCurrency}}</td>
                        <td>{{article.qty}}</td>
                            <template v-if="articles.start_compare_date != '' && articles.stop_compare_date != ''">
                                <td>
                                    {{article.sales_total_compare | toCurrency}}
                                </td>
                                <td>
                                    {{article.qty_compare}}
                                </td>
                            </template>
                        </tr>

                        <tr style="text-align: left; color: red;">
                            <td colspan="3" style="text-align: right; color: red;">
                                <b>Gesamt:</b>
                            </td>
                            <td>{{articles.total_value | toCurrency}}</td>
                            <td>{{articles.total_qty}}</td>
                            <template v-if="articles.start_compare_date != '' && articles.stop_compare_date != ''">
                                <td>{{articles.total_value_compare | toCurrency}}</td>
                                <td>{{articles.total_qty_compare}}</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="callout callout-danger" v-if="articles.articles.data.length <= 0">
                <h5>Keine Verkäufe</h5>
                <p>Im angegeben Zeitraum gibt es keine Verkäufe für diesen Händler. Versuche einen anderen Zeitraum.</p>
            </div>
        </div>  
        <div class="clearfix align-middle card-footer">
            <pagination class="float-left" :data="articles.articles" @pagination-change-page="getResults" :limit="3"></pagination>
            
            <select class="float-right form-control-sm" v-model="params.per_page">
                <option value="25">25 Artikel</option>
                <option value="50">50 Artikel</option>
                <option value="75">75 Artikel</option>
                <option value="100">100 Artikel</option>
                <option value="125">125 Artikel</option>
                <option value="150">150 Artikel</option>
            </select>
            <p v-if="articles.articles.meta != null" class="float-right mr-2">Anzeige Eintrag {{ articles.articles.meta.from }} - {{ articles.articles.meta.to }} von {{ articles.articles.meta.total }}</p>
        </div>   
    </div>
</template>

<script>
export default {
    props: [
        'dealer',
    ],
    data() {
        return {
            params: {
                sort_field: 'articleordernumber',
                sort_direction: 'asc',
                producer_id: null,
                per_page: 25,
                start_date: null,
                stop_date: null,
                start_compare_date: null,
                stop_compare_date: null,
                search: ''
            },
            sum: {
                umsatz: 0,
                umsatz_compare: 0,
                qty: 0,
                qty_compare: 0,
            },
            priceLabels: [],
            showCompare: false,
            articles: {
                articles: {
                    data: [],
                    meta: null,
                }
            },
            producers: [],
        }
    },

    watch: {
        'params.per_page': {
            handler () {
                this.getResults();
            },
            deep: true
        },
        'params.sort_field': {
            handler () {
                this.getResults();
            },
            deep: true
        },
        'params.sort_direction': {
            handler () {
                this.getResults();
            },
            deep: true
        },
    },

    methods:{
        exportStats()
        {
            this.axios
            .get("/dealers/"+ this.dealer.id +"/stats/export", {
                params: {
                    ...this.params
                },
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                );

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download",  'statistik.xlsx');
                document.body.appendChild(link);

                link.click();
            })
            .catch(() => {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Beim Exportieren ist etwas schief gelaufen'
                })
            });
        },

        resetSearch(){
            this.params.search = '';
            this.reload();
        },

        reload(){
            this.getResults();
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        calculateSum(){
            this.sum.qty = 0;
            this.sum.umsatz = 0;
            this.sum.qty_compare = 0;
            this.sum.umsatz_compare = 0;

            // this.articles.data.forEach(element => {
            //     this.sum.qty += element.qty;
            //     this.sum.umsatz += element.sales_total;
            //     this.sum.qty_compare += element.qty_compare;
            //     this.sum.umsatz_compare += element.sales_total_compare;
            // });
        },

        getResults(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/dealers/" + this.dealer.id + "/stats", {
                    params: {
                        page: page,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data.data;
                    this.calculateSum();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadProducers()
        {
            this.axios
            .get("/producers", {
                params: {
                    per_page: 1000
                }
                })
            .then((response) => {
                this.producers = response.data.data;
                })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                })
            })
        },
    },

    mounted(){
        this.getResults();
        this.loadProducers();
    }
}
</script>

<style>

</style>